/* Media query for screens wider than 600px (typically larger screens like desktop/laptops) */
@media screen and (min-width: 600px) {

  .content-inner {
    max-width: 100%; /* Maximum width for larger screens */
    width: 100%;
    margin: 0 auto; /* Center the inner content */
  }

  .upload-content-inner {
    max-width: 600px; /* Maximum width for larger screens */
    width: 100%;
    margin: 0 auto; /* Center the inner content */
  }

  .chat-content-inner {
    display: flex; /* Enable flexbox */
    flex-direction: row; /* Arrange children in a row */
    max-width: 100%; /* Maximum width for the container */
    width: 100%; /* Allow the container to take full width */
    margin: 0 auto; /* Center the container */
    gap: 2px; /* Optional: Add space between items */
}

  .chat-container {
    max-width: 850px;
    width: 100%;
    margin: 0 auto; 
  } 

  .canvas-container {
    max-width: 70%;
    width: 100%;
  } 
  
  .chat-content {
    height: calc(100vh - 280px);
  }

  .question-container {
    max-width: calc(100% - 60px);
    width: 100%;
    height:calc(100% - 60px);
    margin-bottom: 0; 
  }  

  .front-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    background-color:black; 
    background-image: url('https://www.transparenttextures.com/patterns/stardust.png');
    color: #FFFFFF; /* White text */
    background-size: cover; /* Optional: scales the background image to cover entire area */
    background-repeat: no-repeat; /* Optional: prevents repeating patterns */
  }

  .animation-window {
    width: 60%;
  }

  .animation-text {
    margin-top: 200px; 
  }
  
  .auth-box {
    width: 40%;
  }

  .search-bar-container {
    max-width: 580px;
    transform: translateX(-50%); 
    width: 50%;
    position: absolute; 
    top: 10px; 
    left: 40%; 
    margin-left: 120px;
    margin-right: 140px;
    background:transparent;
    border-radius: 8px;
  }  

  .profile-image-container {
    margin-right: 60px;
  }
  .profile-info {
    width : 250px;
  }
  .modal-container {
    flex-direction: row; 
    width: auto; 
  }
  .modal-container.shrink {
    max-width: 100%;
    width: 100%;
    height: calc(100vh - 100px);
  }
  
  .modal-container.expanded {
    max-width: 100%;
    width: 100%;
    height: calc(100vh - 110px);
  }
  
/* Shrink mode - before chat window is opened */
.pdf-viewer-model.shrink {
  max-width: 100%;
  width: 100%;
  height: calc(100vh - 10px);
}

/* Expanded mode - when chat window is opened */
.pdf-viewer-model.expanded {
  max-width: 100%;
  width: 100%;
  height: calc(100vh - 10px);
}
.pdf-viewer-container {
  min-width: none;
  position: relative;
  overflow: auto;
  max-width: 100%;
  width: 100%;
  height: calc(100vh - 150px);
  margin-right: 5px;
  --scale-factor: 1; /* Default scale factor */
  transform: scale(var(--scale-factor)); /* Apply the scale factor */
  transform-origin: 0 0; /* Ensure scaling starts from the top-left corner */
}

.no-text-select {
  user-select: none;
  cursor: grab;
}

.chat-area {
  width: 100%;
}


.zoom-buttons-container,
.action-buttons-container {
  display: flex;
  justify-content: space-between; /* Space out child buttons horizontally */
  align-items: center; /* Ensure buttons are vertically aligned */
  padding: 10px; /* Add some padding for spacing */
  border: 2px solid rgba(255, 255, 255, 0.2); /* Stylish semi-transparent border */
  border-radius: 8px; /* Rounded corners for a modern look */
  background: transparent;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), inset 0 2px 4px rgba(255, 255, 255, 0.1); /* Outer shadow and subtle inset shadow */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}


.video-transcript {
  max-height: 800px; 
}

.chat-window {
  display: flex;
  flex: 1; 
  width: 100%;
  height: calc(100vh - 150px);
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Hides horizontal scrollbar */
}

.chat-input-container {
  max-width: calc(100% - 10px);
  width: 100%;
  overflow: auto;
}

.chat-messages {
  
  max-height: calc(100vh - 250px); 
}

}

/* Media query for small screens (phones or tablets in portrait mode) */
@media screen and (max-width: 599px) {
  .content-inner {
    max-width: 350px; 
    width: 100%;
    margin: 0 auto; /* Center the inner content */
  }

  .upload-content-inner {
    max-width: 350px; 
    width: 100%;
    margin: 0 auto; /* Center the inner content */
  }
  
  .chat-content-inner {
    max-width: 350px; 
    width: 100%;
    margin: 0 auto; /* Center the inner content */
  }
  .chat-container {
    width: 250px;

  } 
  .canvas-container {
    width: 250px;
  } 

  .front-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #000000; /* Black background */
    color: #FFFFFF; /* White text */
    background-size: cover; /* Optional: scales the background image to cover entire area */
    background-repeat: no-repeat; /* Optional: prevents repeating patterns */
  }
  .animation-window {
    width: 500px;
  }

  .animation-text {
    margin-top: 50px; 
    height:200px;
  }
  
  .auth-box {
    margin-top: 0px;
    width: 100%;
  }

  .question-container {
    max-width: calc(100% - 65px);
    width: 100%;
    height:calc(100% - 65px);
    touch-action: none;
  }   

  .search-bar-container {
    position: relative;
    max-width: 100%;
    width: 100%;
    background-color: #2b2f33;
  }

  .profile-image-container {
    margin-right: 20px;
  }
  .profile-info {
    width : 175px;
  }
  .modal-container {
    flex-direction: column; 
    height: auto; 
  }
  .modal-container.shrink {
    max-width: 400px;
    width: 100%;
  }
  
  .modal-container.expanded {
    max-width: 800px;
    width: 100%;
  }
  
/* Shrink mode - before chat window is opened */
.pdf-viewer-model.shrink {
  max-width: 100%;
  width: 100%;
  height: calc(100vh - 10px);
}

/* Expanded mode - when chat window is opened */
.pdf-viewer-model.expanded {
  max-width: 780px;
  width: 100%;
  max-height: 100vh; /* Constrain height to the viewport */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling, if unnecessary */
}

.pdf-viewer-container {
  height: calc(100vh - 100px);
  width:100%;
  margin-right: 5px;
}

.pdf-toolbar {
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-start; /* Align items to the start for better spacing */
  padding: 0px; 
}

.generation-buttons-container {
  width: 100%; 
}

.zoom-buttons-container,
.action-buttons-container {
  width: 90%; /* Make child containers full width */
  display: flex;
  justify-content: space-between; /* Space out child buttons horizontally */
  align-items: center; /* Ensure buttons are vertically aligned */
  padding: 10px; /* Add some padding for spacing */
  border: 2px solid rgba(255, 255, 255, 0.2); /* Stylish semi-transparent border */
  border-radius: 8px; /* Rounded corners for a modern look */
  background: transparent;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), inset 0 2px 4px rgba(255, 255, 255, 0.1); /* Outer shadow and subtle inset shadow */
}


.video-transcript {
  max-height: 400px; 
}

.chat-window {
  display: flex;
  flex: 1; 
  width: 90%;
  min-height: calc(100vh - 100px);
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Hides horizontal scrollbar */
}

.chat-content {
  max-height: 375px; /* Set a maximum height for the chat content */
}

.chat-messages {
  max-height: calc(100vh - 325px); /* Limits height to the viewport height minus 350px */
}

.chat-input-container {
  max-width: calc(100% - 10px);
  width: 100%;
  }

}

@media screen and (min-width: 700px) and (max-width: 1200px) {
  .pdf-toolbar {
    margin-top: 40px;
  }
}

 /*  Change the list items for main chatbot */

.chat-container ul {
  margin-left: -30px; /* Move the list 10px to the left */
  list-style-type: none;
  padding: 0;
}

.chat-container li {
  padding: 5px;
}

 /*  Change the list items for pdf chatbot */
.chat-content ul {
  margin-left: -25px; /* Move the list 10px to the left */
  list-style-type: none;
  padding: 0;
}

.chat-content li {
  padding: 5px;
}

/* General Button Styles */
button {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding: 12px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
}

/* Primary Button Styles */
.primary-button {
  background: linear-gradient(145deg, #6a11cb, #2575fc); /* Cool gradient */
  color: white;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}

.primary-button:hover {
  background: linear-gradient(145deg, #2575fc, #6a11cb); /* Reverse gradient on hover */
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  transform: translateY(-3px); /* Lift effect */
}

.primary-button:active {
  transform: translateY(1px); /* Pressed effect */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Secondary Button Styles */
.secondary-button {
  background: linear-gradient(145deg, #ff7e5f, #feb47b);
  color: white;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}

.secondary-button:hover {
  background: linear-gradient(145deg, #feb47b, #ff7e5f);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  transform: translateY(-3px);
}

.secondary-button:active {
  transform: translateY(1px);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Danger Button Styles */
.danger-button {
  background: linear-gradient(145deg, #ff416c, #ff4b2b);
  color: white;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}

.danger-button:hover {
  background: linear-gradient(145deg, #ff4b2b, #ff416c);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  transform: translateY(-3px);
}

.danger-button:active {
  transform: translateY(1px);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Dropdown Menu */
.dropdown-menu {
  background: #2b2f33;
  border-radius: 12px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  padding: 10px 0;
  width: 240px;
  z-index: 1000;
}

.dropdown-item {
  padding: 15px 20px;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  margin: 5px 10px;
  border-radius: 8px;
}

.dropdown-item:hover {
  background: linear-gradient(145deg, #2575fc, #6a11cb);
  color: #ffffff;
}

.dropdown-item:last-child {
  color: #ff4d4f;
}

.dropdown-item:last-child:hover {
  background: linear-gradient(145deg, #ff4b2b, #ff416c);
}

/* Keyframes for Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

.modal-overlay {
  animation: fadeIn 0.3s ease;
}

.modal-overlay.fade-out {
  animation: fadeOut 0.3s ease;
}

/* Sidebar Styles */
.sidebar {
  background-color: #2b2f33;
  width: 160px;
  height: calc(100vh - 90px);
  min-width: 160px;
  transition: width 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 0;
  position: relative; /* Add relative positioning */
  top: -15px;
}

.sidebar.minimized {
  padding: 0; 
  min-width: 46px;
  max-width: 46px;
  margin-left: -15px;
  margin-right: 5px;  
}

.nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  display: flex;
  align-items: left;
  margin-bottom: 5px;
  justify-content:  flex-start;  /* Center the items when minimized */
}

.sidebar.minimized .nav-item {
  flex-shrink: 0;
  margin-bottom: 5px;
}

.nav-button {
  background: #2b2f33;
  border: none;
  color: #ffffff;
  font-size: 18px;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align buttons to the left */
  transition: all 0.3s ease;
  width: 90%;
  height:30px;
  margin: 0 auto;
  margin-bottom: 30px;
  cursor: pointer;
  text-align: left;
  text-transform: none; 
}

.sidebar.minimized .nav-button {
  font-size: 0;
  width: 30px;
  height: 30px;
  justify-content: flex-start; /* Center icon in minimized state */
  margin-left: 10px; /* Move the buttons towards the left */
  margin-bottom: 30px;
  padding: 0; 
  text-transform: none; 
}

.sidebar.minimized .nav-button .icon {
  margin-right: 0;
}

.nav-button .icon {
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.nav-button:hover {
  background: #2b2f33;
}

.nav-button:active {
  transform: scale(0.98);
}

.nav-button.active .icon {
  color: linear-gradient(145deg, #4a90e2, #357abd);
  transform: scale(1.2);
}

.nav-button.active span {
  color: linear-gradient(145deg, #4a90e2, #357abd);
}

.toggle-button {
  position: absolute;
  left: calc(100% - 30px); /* Position the toggle button to the right edge of the sidebar */
  top: 10px;
  width: 20px;
  background: none;
  border: none;
  cursor: pointer;
  transition: left 0.3s ease;
  color: #ffffff;
  padding:0;
}

.sidebar.minimized .toggle-button {
  left: 30px; /* Adjust the position when the sidebar is minimized */
}

.logout-button {
  width: 100%;
  background-color: #d9534f;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  background: linear-gradient(145deg, #f75c4c, #d53d31);
  font-size: 18px;
  border-radius: 8px;
  display: block;
  margin-top: 20px;
  margin-bottom: 80px;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 15px rgba(255, 65, 108, 0.5);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.logout-button .icon {
  margin-right: 8px;
}

.logout-button-icon-only .icon {
  margin-right: 0;
}

.logout-button-icon-only span {
  display: none;
}

.logout-button:hover {
  background-image: linear-gradient(to right, #ff416c, #ff4b2b);
  box-shadow: 0 6px 20px rgba(255, 65, 108, 0.6);
  transform: translateY(-2px);
}

.logout-button:active {
  transform: translateY(1px);
  box-shadow: 0 3px 10px rgba(255, 65, 108, 0.4);
}

/* Animation Overlay */
.animation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(145deg, rgba(0, 0, 255, 0.3), rgba(128, 0, 128, 0.6)); /* Gradient with blue and purple */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it sits above other elements */
  transition: opacity 1s ease-out; /* Control the fade-out timing */
  opacity: 1; /* Start fully visible */
}


/* Animation Logo */
.animation-logo {
  width: 100px; /* Adjust the size as needed */
  height: 100px;
  animation: rotateAndScaleUpLogo 5s linear forwards, continuousRotate 3s linear infinite; /* Rotate and scale up, then continue rotating */
}

/* Keyframes for rotating and scaling up the logo */
@keyframes rotateAndScaleUpLogo {
  0% {
    transform: scale(0) rotate(0deg); /* Start with the logo at 0 scale (invisible) and no rotation */
  }
  100% {
    transform: scale(1) rotate(360deg); /* Rotate while scaling up to full size */
  }
}

/* Continuous rotation after scaling up */
@keyframes continuousRotate {
  0% {
    transform: rotate(0deg); /* Start with no rotation */
  }
  100% {
    transform: rotate(360deg); /* Complete one full rotation */
  }
}

/* Keyframes for scaling down the logo */
@keyframes rotateAndScaleDownLogo {
  0% {
    transform: scale(1) rotate(0deg); /* Start from full size */
  }
  100% {
    transform: scale(0) rotate(360deg); /* End with 0 scale (invisible) and complete another rotation */
  }
}

/* Apply this class to trigger the fade-out */
.fade-out {
  opacity: 0; /* Start the fade-out */
}

/* General container for the page */

.container-home {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden; /* Prevents scrolling */
}


.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #000000;
}

.container-terms {
  display: flex;
  background-color: #000000;
  background-image: url('https://www.transparenttextures.com/patterns/stardust.png');
  height: 100vh;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: linear-gradient(145deg, #1c1f23, #434b54);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 100;
  height: 70px;
  width: 100%; /* Extend navbar to full width */
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-title {
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  transition: color 0.3s ease;
  width: 150px; /* Set the width to 100px */
  max-width: 150px; /* Ensure the title does not exceed 100px */
  overflow: hidden; /* Hide any overflow text */
  text-overflow: ellipsis; /* Add ellipsis (...) for overflow text */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
}

.navbar-title:hover {
  color: #ffffff; /* Change color on hover */
}

.navbar-right {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar-user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  cursor: pointer;
  margin-right: 0px;
  transition: box-shadow 0.3s ease;
}

.navbar-user-image:hover {
  box-shadow: 0px 0px 10px 2px #4a90e2;
}

.file-user-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  cursor: pointer;
  margin-right: 10px;
}


.navbar-logo {
  height: 40px; /* Adjust the size as needed */
  width: 40px;
  margin-right: 15px;
  vertical-align: middle; /* Align the logo vertically with the text */
  transition: transform 0.3s ease;
}


.main-content {
  margin-top: 0px; /* Ensure main content starts below the navbar */
  padding: 20px; /* Add some padding if needed */
  display: flex;
  flex-direction: row; /* Align child elements horizontally */
  background-color: #2b2f33;
  width: 100%; /* Ensure the main content takes the full width of the screen */
  height: calc(100vh - 70px); /* Subtract the height of the navbar (70px) */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  overflow: hidden; /* Prevents scrolling */
}


.title {
  font-size: 24px;
  font-weight: bold;
}

.nav {
  list-style-type: none;
  padding: 0;
}

.nav-item {
  margin-bottom: 5px;
}

.nav-button:hover {
  color: #007bff; /* Change text and icon color on hover */
  /* Add a light background color on hover */
}

.nav-button.active {
  color: #007bff; /* Maintain blue text color for active button */
  background-color: transparent; /* No background color for active button */
}

.icon {
  margin-right: 8px; /* Space between the icon and the text */
  font-size: 18px; /* Adjust the icon size as needed */
  transition: transform 0.3s ease; /* Added transition for consistency */
  margin-right: 0; /* Remove margin when minimized */
}

/* Profile content styles */
.content {
  width: 100%; /* Adjust the width as needed */
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center; /* Center the profile header content */
}

.profile-image-container {
  margin-top: 20px;  
}

.profile-image {
  width: 100px; /* Increase the size for better visibility */
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-info h1 {
  margin: 0;
  font-size: 24px;
  text-align: center; 
  color: white;
}

.profile-info p {
  margin: 5px 0;
  color: #666;
  text-align: center; 
}

.edit-button {
  background-color: #007bff; /* Blue color */
  color: white; /* White text */
  border: none; /* Remove border */
  padding: 12px 24px; /* Adjusted padding */
  border-radius: 30px;
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Adjust font size */
  transition: background-color 0.3s ease; /* Smooth background transition */
  font-weight: bold;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
}

.edit-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 50%;
}

.edit-button:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

.edit-button:hover {
  box-shadow: 0px 0px 10px 2px #4a90e2;
  transform: translateY(-2px);
}

.edit-button:active {
  transform: translateY(1px);
}

/* Profile stats */
.profile-stats {
  display: flex;
  justify-content: space-around; /* Spread stats evenly */
  max-width: 300px;
  margin: 20px auto; /* Center the stats block */
  color: white;
}

.bio {
  margin-bottom: 20px;
  color: #666;
  text-align: center; /* Center the bio text */
}

.tabs {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  text-align: center; /* Center the tabs */
}

.tab-button {
  background-color: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
}

.tab-button.active {
  border-bottom: 2px solid black;
}

.tab-button.inactive {
  color: #666;
}

/* Content area */
.content-area {
  text-align: center;
  color: #666;
}

.content-area p {
  margin-bottom: 20px;
}

/* EditProfileModal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}

.profile-photo {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-photo-preview {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-field {
  margin-bottom: 15px;
}

.profile-field label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.profile-field input,
.profile-field textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.profile-field textarea {
  resize: none;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.cancel-button,
.save-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button {
  background-color: #ccc;
  margin-right: 10px;
  margin-bottom: 10px;
}

.save-button {
  background-color: #007bff;
  color: white;
  margin-bottom: 10px;
}

/* Enhanced File Drop Area */
.file-drop-area {
  border: 5px dashed rgba(44, 138, 239, 0.5); /* Semi-transparent border with color */
  padding: 40px; /* More padding for a larger drop area */
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform transition */
  height: 120px; /* Increased height for more space */
  background: linear-gradient(145deg, #1c1f23, #434b54); /* Gradient background */
  border-radius: 15px; /* Softer, more modern rounded corners */
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2); /* Stylish shadow effect */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.file-drop-area.dragging {
  background-color: #e3f2fd; /* Lighter color when dragging */
  transform: scale(1.05); /* Slight zoom effect when dragging */
}

.audio-file-drop-area {
  border: 5px dashed rgba(44, 138, 239, 0.5); /* Semi-transparent border with color */
  padding: 10px; /* More padding for a larger drop area */
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform transition */
  height: 70px; /* Increased height for more space */
  background: linear-gradient(145deg, #1c1f23, #434b54); /* Gradient background */
  border-radius: 15px; /* Softer, more modern rounded corners */
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2); /* Stylish shadow effect */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.audio-file-drop-area.dragging {
  background-color: #e3f2fd; /* Lighter color when dragging */
  transform: scale(1.05); /* Slight zoom effect when dragging */
}


.file-creation-date {
  color: white;
  font-size: 0.9em; /* Adjust font size if needed */
  margin-top: 4px;  /* Add spacing if necessary */
}

/* Animated File Upload Icon */
.upload-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  transition: transform 0.3s ease; /* Smooth rotation transition */
}

.upload-icon:hover {
  transform: rotate(360deg); /* Full rotation on hover */
}


.upload-button {
  padding: 12px 24px; /* Increased padding for larger buttons */
  background: linear-gradient(145deg, #6a11cb, #2575fc); /* Cool gradient */
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  margin-top: -40px;
  font-size: 16px; /* Increased font size for better readability */
  font-weight: bold;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); /* Added shadow for depth */
}

.upload-button:hover {
  background-color: #2575fc; /* Change color on hover */
  transform: translateY(-3px); /* Lift effect */
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.upload-button:active {
  transform: translateY(0); /* Pressed effect */
  background-color: #1a5fc2; /* Darker shade when pressed */
}

.textarea-container {
  position: relative;
  width: 100%;
}

/* Enhanced Textarea Input */
.textarea-input {
  width: 90%;
  height: 150px; /* Increased height for more content */
  margin-top: 20px;
  padding: 20px; /* More padding for better spacing */
  font-size: 16px; /* Slightly larger font size */
  border: none; /* Removed border */
  border-radius: 10px;
  background: #2b2f33; /* Dark background for modern look */
  color: white;
  resize: vertical;
  overflow-y: auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Stylish shadow */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.textarea-input:focus {
  outline: none;
  background-color: #3a3f47; /* Lighter background on focus */
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4); /* Elevated shadow on focus */
}

.textarea-input::placeholder {
  color: #bbb; /* Light gray for the placeholder */
  font-style: italic; /* Italicized placeholder for style */
}

/* Centering Text */
.text-center {
  text-align: center;
  color: white;
  font-size: 18px;
}

.image-upload-button {
  padding: 12px 24px; /* Increased padding for larger buttons */
  background: linear-gradient(145deg, #6a11cb, #2575fc); /* Cool gradient */
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px; /* Increased font size for better readability */
  font-weight: bold;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); /* Added shadow for depth */
}

.image-upload-button:hover {
  background-color: #2575fc; /* Change color on hover */
  transform: translateY(-3px); /* Lift effect */
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.image-upload-button:active {
  transform: translateY(0); /* Pressed effect */
  background-color: #1a5fc2; /* Darker shade when pressed */
}
/* Enhanced Submit Button */
.submit-button {
  width: 25%;
  padding: 5px;
  margin-top: 20px;
  background: linear-gradient(145deg, #4caf50, #2e7d32); /* Gradient background */
  color: white;
  border: none;
  border-radius: 5px; /* Modern rounded corners */
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase; /* Uppercase text for modern look */
  transition: background-color 0.3s ease-in-out, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Stylish shadow */
  text-transform: none; 
}

.submit-button:hover {
  background: linear-gradient(145deg, #388e3c, #1b5e20); /* Darker shades on hover */
  transform: translateY(-3px); /* Lift effect */
  box-shadow: 0px 0px 10px 2px #4a90e2;
}

.submit-button:active {
  transform: translateY(0); /* Pressed effect */
  background-color: #1b5e20; /* Solid color when pressed */
}


.close-viewer-button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.close-viewer-button:hover {
  background-color: darkred;
}

.delete-file-button {
  background-color: darkred;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px;
}

.delete-file-button:hover {
  background-color: crimson;
}


.file-list-container {
  background-color: #2b2f33;
  border-radius: 10px;
  padding: 20px;
  width: calc(100% - 100px);
  max-width: calc(100% - 100px);
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden; /* Disable horizontal scrolling */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjusts items per row based on available width */
  gap: 10px;
  margin-left: 40px;
  margin-top: -40px;
}

/* Scrollbar Styling */
.file-list-container::-webkit-scrollbar {
  width: 10px;
}

.file-list-container::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 10px;
}

.file-list-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.file-list-container::-webkit-scrollbar-track {
  background: #2b2f33;
}

.file-list {
  display: contents; 
}

.short-file-list-container {
  background-color: #2b2f33;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  margin: 0 auto;
  height: calc(100vh - 600px);
  overflow-y: auto; /* Add vertical scroll if content exceeds max-height */
}

/* Scrollbar Styling */
.short-file-list-container::-webkit-scrollbar {
  width: 10px;
}

.short-file-list-container::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 10px;
}

.short-file-list-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.short-file-list-container::-webkit-scrollbar-track {
  background: #2b2f33;
}

.video-file-list-container {
  background-color: #2b2f33;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  margin: 0 auto;
  height: calc(100vh - 650px);
  overflow-y: auto; /* Add vertical scroll if content exceeds max-height */
}

/* Scrollbar Styling */
.video-file-list-container::-webkit-scrollbar {
  width: 10px;
}

.video-file-list-container::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 10px;
}

.video-file-list-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.video-file-list-container::-webkit-scrollbar-track {
  background: #2b2f33;
}


.short-file-list {
  list-style-type: none; /* Remove the dots before the list items */
  padding: 0;
  margin: 0;
}


.file-list-item {
  background-color: #2b2f33;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  min-height: 250px;
  max-height: 350px;
  overflow: hidden;
  box-sizing: border-box;
}

.file-list-item:hover {
  background-color: #343a40;
  transform: translateY(-3px);
}

.file-name {
  color: #ffffff;
  font-size: 12px;
  margin-left: 10px;
  margin-right: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  word-wrap: break-word; /* Force wrapping for long words */
  overflow-wrap: break-word; /* Compatibility for different browsers */
  white-space: normal; /* Allow text to wrap onto the next line */
  max-width: 100%; /* Optional: limit the width of the file name container */
}


.file-info {
  color: #ffffff;
  font-size: 14px;
  margin-left: -10px;
  margin-right: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  word-wrap: break-word; /* Force wrapping for long words */
  overflow-wrap: break-word; /* Compatibility for different browsers */
  white-space: normal; /* Allow text to wrap onto the next line */
  max-width: 50%; /* Optional: limit the width of the file name container */
  flex-direction: column;
}


.file-thumbnail {
  width: auto; /* Set the width of the thumbnail */
  height: 200px; /* Maintain aspect ratio */
  margin-bottom: 10px; /* Space between thumbnail and file name */
  border: none;
  border-radius: 4px; /* Rounded corners */
  overflow: hidden; /* Hide any overflow */
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
}

.file-thumbnail img {
  max-width: 100%; /* Ensure the image fits within the thumbnail */
  max-height: 170px; /* Ensure the image does not exceed the thumbnail height */
}

.views-date {
  font-size: 11px;
  color: #aaa;
}
.views-date p {
  margin: 5px 0;
}

.video-thumbnail {
  padding: 0px;
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Space between thumbnail and file name */
  border: none;
  border-radius: 4px; /* Rounded corners */
  overflow: hidden; /* Hide any overflow */
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  background-color: #f9f9f9; /* Light background color */
}

.video-thumbnail img {
  width: 60px;
  height: 60px;
  max-width: 100%; /* Ensure the image fits within the thumbnail */
  max-height: 100%; /* Ensure the image does not exceed the thumbnail height */
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

/* File Actions */
.file-actions {
  display: flex;
  align-items: center;
  gap: 5px; /* Reduced gap between buttons */
  width: 100%; /* Extend to full width of the parent container */
  flex-grow: 1; /* Allow the container to expand within the parent */
  justify-content: space-between; /* Distribute space between elements */
}

.file-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Ensure file name and buttons are on opposite ends */
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #444;
  border-radius: 8px;
  background-color: #1c1f23;
  margin-bottom: 10px;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.file-items:hover {
  background-color: #343a40;
  transform: translateY(-3px);
}

.audio-file-list-item {
  display: flex;
  flex-direction: column;
}

.button-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 2 columns */
  grid-gap: 10px; /* Space between buttons */
  justify-items: center; /* Center the buttons horizontally */
  align-items: center; /* Center the buttons vertically */
}


.button-grid-container-summary {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-gap: 10px; /* Space between buttons */
  justify-items: center; /* Center the buttons horizontally */
  align-items: center; /* Center the buttons vertically */
}

.button-grid-container-public {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* 2 columns */
  grid-gap: 10px; /* Space between buttons */
  justify-items: center; /* Center the buttons horizontally */
  align-items: center; /* Center the buttons vertically */
}


.select-button, .view-button, .qna-button, .share-button,.information-button {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 16px;
  transition: transform 0.2s ease;
  padding: 5px; /* Reduced padding for space */
}


.select-button:hover, .view-button:hover, .qna-button:hover {
  transform: scale(1.2);
}

.select-button.selected {
  color: #4caf50; /* Green color for selected file */
}
/* Share Button Styles */
.information-button {
  color: #61dafb; /* Blue color for share button */
}
/* Share Button Styles */
.share-button {
  color: #61dafb; /* Blue color for share button */
}

.qna-button {
  color: #ffeb3b; /* Default yellow color */
}

.qna-button.completed {
  color: #4caf50; /* Green for completed Q&A */
}

.view-button {
  color: #4a90e2; /* Blue color for view button */
}

.menu-button {
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column; /* Stack dots vertically */
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  gap: 3px; /* Space between each dot */
}

.button-dot {
  width: 3px; /* Width of each dot */
  height: 3px; /* Height of each dot */
  background-color: #ffffff; /* Color of the dots */
  border-radius: 50%; /* Make the dots circular */
}


.menu-container {
  position: relative;
}

/* Updated Menu Content */
.menu-content {
  position: absolute;
  bottom: -10px; /* Position above the button */
  left: -100px; /* Adjust position to the left */
  background-color: #343a40;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 4px;
  z-index: 2000;
  display: block;
}

.menu-content button {
  background: none;
  border: none;
  padding: 4px;
  color: white;
  text-align: left;
  width: 100%;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  z-index: 2000;
}

.menu-content button:hover {
  background-color: #4a90e2;
}

/* Dropdown Menu */
.dropdown-menu {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  padding: 10px 0;
  width: 200px;
  z-index: 1000;
  background-color: #2b2f33; /* Merged with earlier `.dropdown-menu` */
}

.dropdown-item {
  padding: 10px 20px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 5px 0;
  border-radius: 5px;
}

.dropdown-item:hover {
  background-color: #4a90e2;
  color: #ffffff;
}

.dropdown-item + .dropdown-item {
  border-top: 1px solid #e0e0e0;
}

.dropdown-item strong {
  font-weight: bold;
  margin-left: 10px;
}

.dropdown-item:last-child {
  color: #ff4d4f;
}

.dropdown-item:last-child:hover {
  background-color: #ff6b6b;
}

/* Keyframes */
@keyframes moveUp {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Updated question-container */
.question-container {
  padding: 20px;
  border-radius: 20px;
  background-color: #2b2f33;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: -5px;
  margin-bottom: 0px;
  color: #fff;
}

/* Updated text style */
.question-container p {
  font-size: 16px;
  font-weight: bold;
  text-align: center;

}

/* Options list */
.question-container ul {
  font-size: 14px;
  color: white;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0px;
  text-align: left;
}

.question-container li {
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 10px;
  background-color: #282c34;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.question-container li:hover {
  background-color: #3a3f47;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.question-container li.selected {
  background-color: #61dafb;
  color: #20232a;
}

.question-text {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px; /* Space below the question */
}

.options-list {
  font-size: 14px;
  color: white;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0px;
  width: 100%;
  max-width: 400px; /* Optional: Limit the width of options */
}

.option-item {
  padding: 10px;
  margin: 5px 0;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.option-item:hover {
  transform: scale(1.05);
}

.option-item.glowing {
  border: 1px solid #ccc;
  border-radius: 8px;
  border-color: darkgray;
  box-shadow: 0 5px 10px rgb(10, 9, 9); 
}


/* Stylish buttons */
.show-answer-button {
  padding: 10px 20px;
  border-radius: 10px;
  background: linear-gradient(145deg, blue, purple); /* Updated background to gradient */
  color: white;
  border: none;
  cursor: pointer;
  width: 80%;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.show-answer-button:hover {
  background-color: linear-gradient(145deg, blue, purple); 
  transform: translateY(-2px);
  box-shadow: 0px 0px 10px 2px #4a90e2;
}

.show-answer-button:active {
  background-color: #004085;
  transform: translateY(0);
}


/* Stylish buttons */
.answer-submit-button {
  padding: 10px 20px;
  border-radius: 10px;
  background-color: green;
  color: white;
  border: none;
  cursor: pointer;
  width: 80%;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.answer-submit-button:hover {
  background-color: purple;
  transform: translateY(-2px);
  box-shadow: 0px 0px 10px 2px #4a90e2;
}


.answer-submit-button:active {
  background-color: #004085;
  transform: translateY(0);
}


.answer {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.correct {
  color: green;
  margin-top: 0px;
  margin-bottom: 0px;
}

.incorrect {
  color: red;
  margin-top: 0px;
  margin-bottom: 0px;
}

/* Correct answer styling */
.correct-answer {
  font-size: 14px;
  color: white;
  text-align: center;
  margin-top: 0px;
}

/* Icon buttons */
.question-container-icons {
  position: absolute;
  right: -30px; 
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.question-container-icons button {
  background: none;
  border: none;
  cursor: pointer;
  color: #8055f5;
  transition: color 0.3s ease, transform 0.2s ease;
  position: relative; 
  width:30px;
  padding: 0;
  margin-left: 0px;
}

.question-container-icons button:hover {
  color: #007bff;
  transform: scale(1.1);
}

.shuffle-icon.shuffled {
  background-color: darkblue;
  color: white;
}

.shuffle-icon {
  background-color: transparent;
  color: #8055f5;
}

.star-icon.active {
  background-color: darkblue;
  color: white;
}

.star-icon {
  background-color: transparent;
  color: #8055f5;
}


/* Specific styling for the streak icon */
.streak-icon {
  position: relative;
}

.streak-icon span {
  position: absolute;
  top: -10px;
  right: -15px;
  background: #ff4500;
  border-radius: 50%;
  color: white;
  padding: 5px;
  font-size: 14px;
  min-width: 20px;
  text-align: center;
}

/* Optional: Adjust the streak badge to scale smoothly */
.streak-icon:hover span {
  transform: scale(1.1);
  transition: transform 0.2s ease;
}


/* Footer navigation */
.footer-navigation {
  display: flex;
  align-items: center;
  margin-top: -20px;
  justify-content: space-between;
}

.footer-link {
  color: white;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 90px;
  font-size: 14px;
}

.footer-link-library {
  color: white;
  cursor: pointer;
  text-decoration: underline;
  margin-right:50px;
  font-size: 14px;
}
/* Modal Content */
.qwindow-modal {
  background: linear-gradient(145deg, #434b54, #1c1f23);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
  width: 450px;
  text-align: center;
  animation: fadeIn 0.4s ease;
}

.recording-modal {
  background: linear-gradient(145deg, #434b54, #1c1f23);
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
  width: 580px;
  text-align: center;
  animation: fadeIn 0.4s ease;
  margin-bottom: 5px;
}

.modal-title {
  font-size: 26px;
  color: #ffffff;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.qwindow-row {
  margin-bottom: 20px;
}

.qwindow-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
}

.qwindow-input, .qwindow-select, .qwindow-textarea {
  width: 100%;
  padding: 12px;
  margin-top: 10px;
  border: none;
  border-radius: 10px;
  background: #2b2f33;
  color: #ffffff;
  font-size: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.asswindow-select, .asswindow-textarea {
  width: 80%;
  padding: 12px;
  margin-top: 10px;
  border: none;
  border-radius: 10px;
  background: #2b2f33;
  color: #ffffff;
  font-size: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.inputContainer {
  display: block; /* Ensure it's a block element */
}
.asswindow-input {
  width: 80% !important;/* Reduced width for Assistant Name input */
  padding: 12px;
  margin-top: 10px;
  border: none !important;
  border-radius: 10px;
  background: #2b2f33;
  color: #ffffff;
  font-size: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}


.qwindow-input:focus, .qwindow-select:focus, .qwindow-textarea:focus {
  outline: none;
  background-color: #3a3f47;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.4);
}


.asswindow-input:focus, .asswindow-select:focus, .asswindow-textarea:focus {
  outline: none;
  background-color: #3a3f47;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.4);
}

.asswindow-textarea {
  height: 150px;
  resize: none;
}

.asswindow-actions {
  display: flex;
  justify-content: space-between;
}



.qwindow-textarea {
  height: 150px;
  resize: none;
}

.qwindow-actions {
  display: flex;
  justify-content: space-between;
}

.qwindow-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.qwindow-button:hover {
  background-color: #388e3c;
  transform: translateY(-3px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.qwindow-button:active {
  background-color: #2e7d32;
  transform: translateY(0);
}

.qwindow-button.cancel-button {
  background-color: #d9534f;
}

.qwindow-button.cancel-button:hover {
  background-color: #c9302c;
}

.qwindow-button.cancel-button:active {
  background-color: #ac2925;
}

/* Remove bullet points from the list */
.subject-list {
  list-style-type: none; /* Removes bullets */
  padding: 0; /* Removes default padding */
}

.subject-item {
  margin-bottom: 10px; /* Adds spacing between list items */
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(145deg, rgba(0, 0, 255, 0.6), rgba(128, 0, 128, 0.6));
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 1;
  animation: fadeIn 0.3s ease;
}

/* Modal Content */
.swindow-modal {
  background: linear-gradient(145deg, #434b54, #1c1f23);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.5);
  width: 400px;
  text-align: center;
  animation: fadeIn 0.3s ease;
}

/* Title */
.modal-title {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* Input Field */
.swindow-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: none;
  border-radius: 10px;
  background: #2b2f33;
  color: #ffffff;
  font-size: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.swindow-input:focus {
  background-color: #3a3f47;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.4);
  outline: none;
}

/* List */
.swindow-list {
  list-style-type: none;
  padding: 0;
}

.swindow-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  background-color: #2b2f33;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.swindow-item:hover {
  background-color: #3a3f47;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
}

.swindow-checkbox {
  margin-right: 10px;
}

.swindow-checkbox-label {
  color: #ffffff;
  font-size: 16px;
}

/* Actions */
.swindow-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.swindow-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.swindow-button:hover {
  background-color: #388e3c;
  transform: translateY(-3px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.swindow-button:active {
  background-color: #2e7d32;
  transform: translateY(0);
}

.swindow-button.cancel-button {
  background-color: #d9534f;
}

.swindow-button.cancel-button:hover {
  background-color: #c9302c;
}

.swindow-button.cancel-button:active {
  background-color: #ac2925;
}

.swindow-button.add-button {
  background-color: #1e90ff;
}

.swindow-button.add-button:hover {
  background-color: #007bff;
}

.swindow-button.remove-button {
  background-color: #ff5722;
}

.swindow-button.remove-button:hover {
  background-color: #e64a19;
}

/* Subject Group Title */
.subject-group-title {
  font-size: 18px;
  color: #61dafb;
  margin-bottom: 10px;
}

/* Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Modal Overlay */
.pmodal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(145deg, rgba(0, 0, 255, 0.6), rgba(128, 0, 128, 0.6));
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

/* Modal Content */
.pwindow-modal {
  background: linear-gradient(145deg, #434b54, #1c1f23);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.5);
  width: 400px;
  text-align: center;
  animation: fadeIn 0.3s ease;
}

/* Title */
.modal-title {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* Profile Photo */
.profile-photo {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-photo-preview {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 10px;
}

.file-input {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #2b2f33;
  color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-input:hover {
  background-color: #3a3f47;
}

/* Input Fields */
.pwindow-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: none;
  border-radius: 10px;
  background: #2b2f33;
  color: #ffffff;
  font-size: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.pwindow-input:focus {
  background-color: #3a3f47;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.4);
  outline: none;
}

/* Textarea */
.pwindow-textarea {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 10px;
  background: #2b2f33;
  color: #ffffff;
  font-size: 16px;
  height: 100px;
  resize: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.pwindow-textarea:focus {
  background-color: #3a3f47;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.4);
  outline: none;
}

/* Character Count */
.character-count {
  color: #bbb;
  font-size: 14px;
  text-align: right;
  margin-top: 0px;
  margin-bottom: 10px;
}

/* Actions */
.pwindow-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.pwindow-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.pwindow-button:hover {
  background-color: #388e3c;
  transform: translateY(-3px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.pwindow-button:active {
  background-color: #2e7d32;
  transform: translateY(0);
}

.pwindow-button.cancel-button {
  background-color: #d9534f;
}

.pwindow-button.cancel-button:hover {
  background-color: #c9302c;
}

.pwindow-button.save-button {
  background-color: #1e90ff;
}

.pwindow-button.save-button:hover {
  background-color: #007bff;
}

/* Label */
.field-label {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Animation for the drop area */
@keyframes dropAreaHighlight {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

/* Stylish Dropdown Menu */
select {
  padding: 12px 16px;
  font-size: 16px;
  width: 100%;
  background: linear-gradient(145deg, #2b2f33, #3a3f47);
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  appearance: none; /* Hide the default dropdown arrow */
  position: relative;
  outline: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

select:hover {
  background-color: #4a90e2;
  box-shadow: 0px 6px 15px rgba(74, 144, 226, 0.4);
}

select:focus {
  background-color: #61dafb;
}

select::after {
  content: '▼';
  font-size: 12px;
  color: white;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/* Stylish Library Items */
.library-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
  background: linear-gradient(145deg, #2b2f33, #434b54);
  border-radius: 12px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  padding: 20px;
}

.library-item {
  background-color: #1c1f23;
  border-radius: 12px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.library-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
}

.library-card {
  padding: 20px;
  background-color:  linear-gradient(145deg, #1c1f23, #434b54);
  border-radius: 10px;
  color: white;
  font-size: 18px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.library-card:hover {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
}

.library-card p {
  margin-bottom: 10px;
  font-weight: bold;
  color: #fff;
}

.library-card ul {
  padding-left: 20px;
  margin-top: 10px;
  list-style-type: none;
}

.library-card ul li {
  margin-bottom: 8px;
  color: #ccc;
}

.library-card ul li strong {
  color: #4a90e2;
}


.library-checkbox {
  padding: 0px;
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  color:#00c8ff;
  top: 0px; /* Moves the button upward */
}

/* Modernized Library Buttons */
.library-button {
  padding: 10px 15px;
  border: none;
  border-radius: 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.library-button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}

.library-button:active {
  transform: translateY(1px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.library-button.show-answer {
  background: linear-gradient(145deg, #6a11cb, #2575fc);
  color: white;
}

.library-button.show-answer:hover {
  background: linear-gradient(145deg, #2575fc, #6a11cb);
}

.library-button.delete {
  background: linear-gradient(145deg, #ff416c, #ff4b2b);
  color: white;
}

.library-button.delete:hover {
  background: linear-gradient(145deg, #ff4b2b, #ff416c);
}

/* Scrollbar Styling for Library Items */
.library-items::-webkit-scrollbar {
  width: 8px;
}

.library-items::-webkit-scrollbar-thumb {
  background-color: #4a90e2;
  border-radius: 8px;
}

.library-items::-webkit-scrollbar-track {
  background-color: #2b2f33;
}


/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

/* Modal Overlay */
.modal-overlay-pdfViewer {
  position: fixed;
  top: 30px;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(145deg, rgba(0, 0, 255, 0.7), rgba(128, 0, 128, 0.7));
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}


/* Modal Content */
.stylish-modal {
  background: linear-gradient(145deg, #434b54, #1c1f23);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.5);
  width: 450px;
  text-align: center;
  animation: fadeIn 0.4s ease;
}

/* Modal Title */
.modal-title {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* Stylish Select */
.stylish-select {
  width: 100%;
  padding: 12px;
  background: linear-gradient(145deg, #2b2f33, #3a3f47);
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  appearance: none;
  outline: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.stylish-select:hover {
  background-color: #4a90e2;
  box-shadow: 0px 6px 15px rgba(74, 144, 226, 0.4);
}

.stylish-select:focus {
  background-color: #61dafb;
}

/* Modal Actions */
.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Stylish Buttons */
.stylish-button {
  padding: 12px 24px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}

.stylish-button:hover {
  transform: translateY(-3px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
}

.stylish-button:active {
  transform: translateY(1px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Cancel Button */
.cancel-button {
  background: linear-gradient(145deg, #d9534f, #c9302c);
  color: white;
}

.cancel-button:hover {
  background: linear-gradient(145deg, #c9302c, #d9534f);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
}

/* Add Button */
.add-button {
  background: linear-gradient(145deg, #4caf50, #388e3c);
  color: white;
}

.add-button:hover {
  background: linear-gradient(145deg, #388e3c, #4caf50);
}

/* Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.library-share-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #61dafb; 
  font-size: 12px;
  transition: transform 0.2s ease;
  padding: 0px; /* Adjust the padding to keep it consistent */
}

.library-share-button:hover {
  transform: scale(1.2);
  color: blue/* Adjust color on hover if needed */
}

.library-remove-button {
  background: none;
  border: none;
  cursor: pointer;
  color: red; 
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
  transition: transform 0.2s ease;
  padding: 0px; /* Adjust the padding to keep it consistent */
}

.library-remove-button:hover {
  transform: scale(1.2);
  color: pink/* Adjust color on hover if needed */
}


.game-selection {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-gap: 20px; /* Space between the grid items */
  justify-items: center; /* Center items horizontally */
  padding: 20px;
}

.game-button {
  background-color: #444;
  color: white;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 200px; /* Button width */
  text-align: center;
}

.game-button:hover {
  background-color: #666;
}


.break-title {
  color: white;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.break-timer {
  color: white;
  font-size: 18px;
  text-align: center;
  margin-bottom: 30px;
}

.suggestions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.suggestion-item {
  color: #00d1b2; /* Cool and stylish green */
  font-size: 16px;
  font-weight: 500;
  transition: transform 0.3s;
}

.suggestion-item:hover {
  transform: scale(1.1);
}

.home-page-link {
  margin-top: 20px;
  margin-left: 480px;
  color: white;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}

.home-page-link:hover {
  color: #00d1b2; /* Stylish hover effect */
}


/* Primary Button Styles */
.library-primary-button {
  width: 100px;
  background: linear-gradient(145deg, #6a11cb, #2575fc); /* Cool gradient */
  color: white;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}

.library-primary-button:hover {
  background: linear-gradient(145deg, #2575fc, #6a11cb); /* Reverse gradient on hover */
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  transform: translateY(-3px); /* Lift effect */
}

.library-primary-button:active {
  transform: translateY(1px); /* Pressed effect */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Secondary Button Styles */
.library-secondary-button {
  width:100px;
  background: linear-gradient(145deg, #ff7e5f, #feb47b);
  color: white;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}

.library-secondary-button:hover {
  background: linear-gradient(145deg, #feb47b, #ff7e5f);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  transform: translateY(-3px);
}

.library-secondary-button:active {
  transform: translateY(1px);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}


/* Primary Button Styles */
.library-primary-button1 {
  width: 80px;
  background: linear-gradient(145deg, #6a11cb, #2575fc); /* Cool gradient */
  color: white;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  font-size: 10px;
}

.library-primary-button1:hover {
  background: linear-gradient(145deg, #2575fc, #6a11cb); /* Reverse gradient on hover */
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  transform: translateY(-3px); /* Lift effect */
}

.library-primary-button1:active {
  transform: translateY(1px); /* Pressed effect */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Secondary Button Styles */
.library-secondary-button1 {
  width:80px;
  background: linear-gradient(145deg, #ff7e5f, #feb47b);
  color: white;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  font-size: 10px;
}

.library-secondary-button1:hover {
  background: linear-gradient(145deg, #feb47b, #ff7e5f);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  transform: translateY(-3px);
}

.library-secondary-button1:active {
  transform: translateY(1px);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.navbar-notification-icon {
  color: white;
  cursor: pointer;
  margin-right: 30px;
}


.navbar-dropdown-menu {
  position: absolute;
  width: 150px;
  top: 50px;
  right: 70px;
  z-index: 1000;
  background-color: #2b2f33;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.navbar-dropdown-item {
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
}

.navbar-dropdown-item:hover {
  background: #2b2f33;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
}

.navbar-dropdown-icon {
  margin-right: 10px;
}

.remove-account {
  color: red;
}

.notification-wrapper {
  position: relative;
}

.notification-count {
  position: absolute;
  top: -5px;
  right: 15px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: bold;
}

.notification-panel {
  position: absolute;
  top: 40px;
  right: 0;
  background: #2b2f33;
  border: none;
  border-radius: 15px;
  padding: 10px;
  width: 300px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-height: 400px; /* Adjust the max height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
}


.notification-item {
  color: white;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.notification-item:last-child {
  border-bottom: none;
}

.unread-notification {
  background-color: #f5f5f5; /* Highlight unread notifications */
  font-weight: bold;
}

.notification-date {
  font-size: 0.8em;
  color: #888;
}

.billing-card {
  background-color: #3a3f44;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.product-details {
  margin-bottom: 20px;
}

.product-name {
  font-size: 20px;
  font-weight: bold;
  color: white;
}

.product-description {
  font-size: 16px;
  margin: 10px 0;
  color: white;
}

.product-price {
  font-size: 18px;
  color: #ffffff;
}

.checkout-section {
  margin-top: 20px;
}

.checkout-button {
  background: linear-gradient(145deg, rgba(0, 0, 255, 0.6), rgba(128, 0, 128, 0.6));
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.checkout-button:hover {
  background: linear-gradient(145deg, rgba(0, 0, 255, 0.6), rgba(128, 0, 128, 0.6));
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
}

.plan-title {
  font-size: 24px;
  margin-bottom: 30px;
  color: #ffffff;
}

.plans-content {
  width: 100%; /* Adjust the width as needed */
  overflow-y: auto;
}

.plan-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

.plan-table th {
  text-align: left;
  padding: 15px;
  background-color: #3a3f44;
  color: #00d1b2;
  border-bottom: 1px solid #444;
}

.plan-table td {
  background-color: #3a3f44;
  padding: 15px;
  border-bottom: 1px solid #444;
  color: #ffffff;
}

.color-settings {
  margin-top: 20px;
  padding: 10px;
  background-color: #2b2f33;
  border-radius: 10px;
}

.color-settings input[type="color"] {
  margin-left: 10px;
  border: none;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.color-row-container {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center;    /* Align items vertically if necessary */
  margin-top: 20px;       /* Optional: Adjust spacing */
}


.color-row {
  display: flex;
  align-items: center;
  gap: 15px; /* Add space between the label and the color picker */
}

.color-settings h2 {
  margin-bottom: 10px;
  color: #ffffff;
}

.default-color-button {
  background: linear-gradient(145deg, #6a11cb, #2575fc); 
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.default-color-button:hover {
  background-color: #1f2528;
  box-shadow: 0px 0px 10px 2px #4a90e2;
}



/* Modal Content */
.notes-share-modal {
  background-color: #2b2f33;
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  text-align: center;
}


/* Modal Row */
.notes-share-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

.notes-share-label {
  color: #b0b0b0;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
}

/* Input and Textarea */
.notes-share-input,
.notes-share-textarea {
  width: 90%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #484d52;
  background-color: #1f1f23;
  color: #ffffff;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.notes-share-input:focus,
.notes-share-textarea:focus {
  border-color: #61dafb;
  outline: none;
}

/* Textarea */
.notes-share-textarea {
  height: 100px;
  resize: none;
}

/* Modal Actions */
.notes-share-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.notes-share-send-button {
  background-color: green;
  border: none;
  color: #20232a;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
}

.notes-share-send-button:hover {
  background-color: #4db8e6;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
}

.plan-title {
  font-size: 3rem;
  margin-bottom: 40px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 15px #c090ff; /* Neon purple glow */
}

.plan-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 20px;
}

.plan-table th, .plan-table td {
  padding: 15px 25px;
  text-align: left;
  background-color: #1a1a2e; /* Deep, dark blue */
  border: none;
  border-radius: 12px;
  color: #a6f6f1; /* Light neon cyan */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.plan-table th {
  font-weight: bold;
  font-size: 1.0rem;
  color: white; /* Neon gold */
}

.plan-table td {
  text-align: center;
  color: #c5c6e6; /* Cool, modern pastel blue */
}

.plan-table th:hover, .plan-table td:hover {
  background-color: #121212; /* Darken on hover */
  color: #ffffff; /* Brighten text on hover */
}

/* Checkmark and cross styles */
.checkmark {
  color: #32ff7e; /* Bright neon green */
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: 0px 0px 5px #32ff7e; /* Neon green glow */
}

.cross {
  color: #ff3838; /* Neon red */
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: 0px 0px 10px #ff3838; /* Neon red glow */
}

.upgrade-button {
  background: linear-gradient(145deg, #8d05e7, #493240); /* Bright gradient button */
  color: white;
  border: none;
  padding: 16px 32px;
  cursor: pointer;
  border-radius: 15px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: none;
}

.upgrade-button:hover {
  background: linear-gradient(145deg, #7f23e7, #6d6a8d); /* Neon glow gradient on hover */
  transform: translateY(-4px); /* Lift effect */
}

.upgrade-button-red {
  background: linear-gradient(145deg, #3f77f0, #6659f1); 
  color: white;
  width: 200px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 15px;
  transition: background-color 0.3s ease;
}

.plans-button:hover {
  background: linear-gradient(145deg, #7f23e7, #6d6a8d); /* Neon glow gradient on hover */
  transform: translateY(-4px); /* Lift effect */
}

.plans-button-red {
  background: linear-gradient(145deg, #3f77f0, #6659f1); 
  color: white;
  width: 150px;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 15px;
  transition: background-color 0.3s ease;
}

.register-button {
  width: 50%;
  padding: 15px;
  margin-top: 30px;
  background: linear-gradient(145deg, #4caf50, #2e7d32); /* Gradient background */
  color: white;
  border: none;
  border-radius: 25px; /* Modern rounded corners */
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase; /* Uppercase text for modern look */
  transition: background-color 0.3s ease-in-out, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Stylish shadow */
}

.register-button:hover {
  background: linear-gradient(145deg, #388e3c, #1b5e20); /* Darker shades on hover */
  transform: translateY(-3px); /* Lift effect */
  box-shadow: 0px 0px 10px 2px #4a90e2;
}

.error{
  color:red;
}

.price-list{
  color:white;
}

.modern-checkout-section {
  background: linear-gradient(145deg, #1e2a38, #24344b);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  color: white;
  max-width: 400px;
  margin: 20px auto;
}

.checkout-form {
  display: flex;
  flex-direction: column;
}

.form-label {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
  color:white;
}

.form-input {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ddd;
  margin-top: 5px;
  background-color: #f5f5f5;
}

.card-element-container {
  margin-bottom: 15px;
}

.card-element {
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}



.message-box {
  margin-top: 10px;
  font-size: 14px;
  color: #ff4d4d;
  white-space: pre-wrap;
}


.billing-card {
  padding: 30px;
  background-color: #273142;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.card-brand-logo {
  width: 40px; /* Adjust the size of the logo */
  height: auto;
  margin-left: 10px; /* Space between the input and logo */
  vertical-align: middle;
  display: inline-block;
}


.card-checkout-logo {
  width: 200px; /* Adjust the size of the logo */
  height: auto;
  margin-left: 10px; /* Space between the input and logo */
  margin-bottom: 10px;
  vertical-align: middle;
  display: inline-block;
}


.card-type{
  color:white;
  margin-bottom: 20px;
}

/* Add this CSS in your Base.css */
.card-form-input {
  width: 96%; /* Slightly reduce the width of the full name input field */
  padding: 10px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 16px;
  font-family: 'Roboto', 'Open Sans', 'Segoe UI', sans-serif;
}

.card-form-input::placeholder {
  color: #a0aec0; /* Gray color for placeholder */
}


/* Title styling */
.billing-title {
  color: rgba(238, 93, 238, 0.6);
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}

/* Subscription and account information styling */
.billing-card {
  background-color: #34344b;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
}

.checkout-section {
  color: white;
  font-size: 18px;
}

.subscription-heading {
  color: #ffce54;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

/* Links and buttons */
a.add-subscription-link {
  color: #00c8ff;
  text-decoration: none;
  font-size: 18px;
  text-align: center;
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #00c8ff;
  border-radius: 8px;
  transition: all 0.3s ease;
}

a.add-subscription-link:hover {
  background-color: #00c8ff;
  color: #1e1e2f;
}

.sub-cancel-link {
  color: #ff4d4d;
  text-decoration: none;
  font-weight: bold;
  position: relative;
  display: inline-block;
  transition: color 0.3s ease, transform 0.3s ease;
}

.sub-cancel-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ff4d4d;
  transition: width 0.3s ease;
}

.sub-cancel-link:hover {
  color: #e60000; /* Darker red on hover */
  transform: translateY(-2px); /* Slight lift effect on hover */
}

.sub-cancel-link:hover::after {
  width: 100%; /* Underline expands on hover */
}

.sub-cancel-link:active {
  color: #cc0000; /* Even darker red when clicked */
  transform: translateY(0); /* Reset the lift effect on click */
}

.sub-cancel-link:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(255, 77, 77, 0.6); /* Focus outline for accessibility */
}

/* Subscription container styling */
.subscription-container {
  background-color: transparent;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  color:white;
}

/* Text highlight */
.text-highlight {
  color:  rgba(238, 93, 238, 0.6);
}

/* Divider */
.divider {
  border: 1px solid #3b3b5d;
}

/* Error message */
.error-message {
  color: red;
  font-size: 16px;
  text-align: center;
}

/* No subscriptions message */
.no-subscriptions {
  text-align: center;
  font-size: 18px;
  color: #ffce54;
}


.invoice-link, .invoice-download-link {
  display: inline-block;
  padding: 12px 20px;
  margin: 10px 0;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(145deg, rgba(0, 0, 255, 0.6), rgba(128, 0, 128, 0.6));
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.invoice-link:hover, .invoice-download-link:hover {
  background-color: #45a049; /* Darker green on hover */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  transform: translateY(-2px); /* Slight lift effect on hover */
}

.invoice-link:active, .invoice-download-link:active {
  background-color: #3e8e41; /* Even darker green when active (clicked) */
  transform: translateY(0); /* Reset the lift effect on click */
}

.invoice-link:focus, .invoice-download-link:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(72, 187, 120, 0.8); /* Focus outline for accessibility */
}
.pdf-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(15,15,85,0.8), rgba(0,0,40,0.9)); /* Space-like gradient */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

/* Star-like elements */
.stars {
  width: 2px;
  height: 2px;
  background: white;
  box-shadow: 
    0 0 2px 2px white, 
    30px 40px 2px 2px white, 
    60px 80px 2px 2px white, 
    90px 120px 2px 2px white, 
    120px 160px 2px 2px white;
  position: absolute;
  top: 10%;
  left: 10%;
  animation: move-stars 20s linear infinite;
}

/* Small stars randomly spread across the screen */
.stars::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  background: white;
  box-shadow: 
    10px 20px white, 
    30px 50px white, 
    50px 70px white, 
    70px 90px white, 
    90px 120px white;
  animation: move-stars 25s linear infinite;
}

/* Keyframes to animate the star movement */
@keyframes move-stars {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-1000px);
  }
}

.pdf-modal {
  background: rgba(0, 0, 40, 0.85);
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.4);
  width: 80%;
  height: 80%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.pdf-toolbar {
  gap: 5px;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap; /* Allow wrapping when necessary */
  user-select: none;
}

/* Zoom Button Custom Styles */
.pdf-toolbar button {
  background: transparent;
  border: none;
  color: white;
  font-size: 16px;
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 25px;
  transition: all 0.3s ease;
}

.pdf-toolbar button:hover {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  transform: none;
}

.collect-close-buttons {
  display: flex;
  justify-content: space-between; /* Space out child buttons horizontally */
  align-items: center; /* Ensure buttons are vertically aligned */
  padding: 10px; /* Add some padding for spacing */
  background: transparent;
}

.close-viewer-button {
  background: linear-gradient(45deg, #ff416c, #ff4b2b);
  color: white;
  border: none;
  padding: 8px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 25px;
  box-shadow: 0 2px 5px rgba(255, 100, 100, 0.4);
  transition: all 0.3s ease;
}

.close-viewer-button:hover {
  background: linear-gradient(45deg, #ff4b2b, #ff416c);
  box-shadow: 0 4px 10px rgba(255, 100, 100, 0.6);
}

.pdf-viewer-container {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.2);
  padding: 10px;
  --rpv-core__inner-page-background-color:  linear-gradient(145deg, rgba(0, 0, 255, 0.6), rgba(128, 0, 128, 0.6));
  --rpv-core__page-layer-box-shadow: 2px 2px 8px 0  linear-gradient(145deg, rgba(0, 0, 255, 0.6), rgba(128, 0, 128, 0.6));
  transition: transform 0.3s ease-in-out; 
}

.fullscreen-mode {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #282c34;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-content {
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.fullscreen-question-container {
  width: 100%;
  height: 100%;
  background-color: linear-gradient(145deg, #1c1f23, #434b54);
  padding: 20px;
  box-shadow: none;
  overflow-y: hidden;
}

.fullscreen-chat-container {
  width: 100%;
  height: 100%;
  background-color: linear-gradient(145deg, #1c1f23, #434b54);
  padding: 20px;
  box-shadow: none;
  overflow-y: hidden;
}

.fullscreen-icon {
  background: transparent;
  border: none;
  cursor: pointer;
  color: white;
  padding: 8px;
  transition: background-color 0.3s ease;
}

.fullscreen-icon:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.payment-status-section{
  color: red;
}

.subscription-cancel-link {
  color: blue;
  text-decoration: none;
  font-weight: bold;
  position: relative;
  display: inline-block;
  transition: color 0.3s ease, transform 0.3s ease;
}



.cancel-text p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 15px;
  color: #555;
}

.signature {
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
}

.cancel-section {
  text-align: center;
}

.cancel-button {
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cancel-button:hover {
  background-color: #e04343;
}

/* Space-Themed Modal Styles */

/* Background overlay */
.space-theme-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(10,10,35,0.8) 0%, rgba(0,0,0,0.95) 100%), url('https://www.transparenttextures.com/patterns/dark-matter.png'); 
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal styling */
.space-theme-modal {
  background-color: rgba(20, 20, 60, 0.95);
  padding: 30px;
  border-radius: 15px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.3s ease-in-out;
  color: #fff;
  backdrop-filter: blur(10px);
}

/* Modal heading */
.space-theme-heading {
  margin-bottom: 20px;
  font-size: 28px;
  color: #f8f9fa;
  font-family: 'Orbitron', sans-serif; /* Use a futuristic space font */
  letter-spacing: 1.5px;
}

/* Modal text */
.space-theme-text {
  font-size: 18px;
  line-height: 1.6;
  color: #c1c1c1;
  margin-bottom: 30px;
  font-family: 'Roboto', sans-serif;
}

/* Modal actions */
.modal-actions {
  display: flex;
  justify-content: space-around;
}

/* Space-themed buttons */
.space-theme-button {
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 8px;
  color: white;
  background: linear-gradient(45deg, #ff007f, #8700ff);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.space-theme-button:hover {
  background: linear-gradient(45deg, #ff80bf, #a04dff);
  transform: translateY(-3px);
}

/* Confirm button specific color */
.modal-button.confirm {
  background: linear-gradient(45deg, #ff007f, #8700ff);
}

.modal-button.cancel {
  background: linear-gradient(45deg, #007bff, #00ccff);
}

.modal-button.confirm:hover {
  background: linear-gradient(45deg, #ff80bf, #a04dff);
}

.modal-button.cancel:hover {
  background: linear-gradient(45deg, #80d4ff, #4da6ff);
}

/* Keyframe animation */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}


/* Reduce table width to 2/3 */
.reduced-width-table {
  width: 45%; /* Set the total width of the table to 2/3 */
  margin: 0 auto; /* Center the table */
  font-size: 14px; /* Make the font slightly smaller to fit better */
  border-collapse: collapse; /* Remove extra spacing between cells */
}

/* Style the table cells */
.plan-table th, 
.plan-table td {
  padding: 6px 8px; /* Adjust padding to make cells more compact */
  text-align: left;
  vertical-align: middle;
}



.plan-table td {
  width: 15%; /* Narrow down the 2nd, 3rd, and 4th columns */
}

.upgrade-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.upgrade-button:hover {
  background-color: #0056b3;
}

.checkmark, .cross {
  text-align: center;
  font-size: 16px;
}

.checkmark {
  color: green;
}

.cross {
  color: red;
}

/* Ensure the parent container has a centered alignment */
.container {
  text-align: center; /* Center text and inline elements within the container */
}

/* Update the home page link styles */
.plan-home-page-link {
  margin-top: 20px;
  margin-left: 0; /* Remove the fixed margin */
  margin-right: 0; /* Reset right margin */
  color: white;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  display: inline-block; /* Ensure the link is treated as a block to respect margins */
  padding: 5px 10px; /* Optional padding to make it more clickable */
}

.plan-home-page-link:hover {
  color: #00d1b2; /* Stylish hover effect */
}

/* Base.css */

.button-glow-effect {
  position: relative;
  z-index: 100; /* Ensure the button is on top of the background */
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

.button-glow-effect:hover {
  box-shadow: 0 0 20px 5px rgba(88, 166, 255, 0.8); /* Glowing effect */
  transform: scale(1.05); /* Slight scale up */
}

.search-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0px;
  z-index: 1000; /* Ensure it appears above the NavBar */
}


.search-input {
  border: none;
  background: transparent;
  color: #fff;
  font-size: 14px;
  flex: 1;
  padding: 8px;
  margin-right: 10px;
  margin-left: 20px;
}

.search-input:focus {
  outline: none;
}

.search-icon {
  color: #888;
  font-size: 20px;
  margin-right: 10px;
}

.icon-button {
  background: none; /* No background */
  border: none; /* No border */
  padding: 0; /* Remove default padding */
  cursor: pointer; /* Pointer cursor */
  outline: none; /* Remove outline on focus */
  color:#00c8ff;
  margin-right:20px;
}

.icon-button:hover {
  color: #007bff; /* Change color on hover to blue or any preferred color */
}

.json-viewer {
  color: black; /* Set text color to black */
  background-color: white; /* Set background color to white */
  padding: 16px; /* Optional: add some padding around the JSON */
  border-radius: 8px; /* Optional: round the corners */
  font-family: monospace; /* Optional: use monospace font for better readability of JSON */
  overflow-x: auto; /* Optional: allow horizontal scrolling if the JSON is too wide */
  white-space: pre-wrap; /* Ensure line breaks are respected */
}


.info-modal-content {
  background: #2b2f33;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 100%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.info-modal-content h2 {
  margin-bottom: 15px;
  font-size: 24px;
  color: white;
}

.info-modal-content p {
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
}

.close-button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.close-button:hover {
  background-color: #0056b3;
}

.profile-close-button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.profile-close-button:hover {
  background-color: #0056b3;
}


.menu-option {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-transform: none; /* Ensure normal case */
}

.menu-option:hover {
  background-color: #f0f0f0;
}

/* Chat container layout */
.chat-container {
  display: flex;
  padding: 20px;
  background-color: #f4f4f9;
}

.canvas-container {
  display: flex;
  padding: 20px;
  background-color: #f4f4f9;
}


.assistant-list {
  width: 20%;
  padding: 10px;
  background-color: #e9ecef;
  border-right: 1px solid #ccc;
}

.assistant-list h3 {
  margin-top: 0;
}

.assistant-list ul {
  list-style-type: none;
  padding: 0;
}

.assistant-list li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  transition: background-color 0.2s ease;
}

.assistant-list li:hover {
  background-color: #ddd;
}


/* Fullscreen mode styles */
.fullscreen-chat-container .chat-container {
  width: 88%; /* Wider chat container in full-screen mode */
  height: calc(100vh - 60px); /* Adjust height to fit the screen, accounting for navbar height */
  padding: 20px;
  border-radius: 10px;
  background-color: #2b2f33;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
}

.fullscreen-chat-container .canvas-container {
  width: 88%; /* Wider chat container in full-screen mode */
  height: calc(100vh - 60px); /* Adjust height to fit the screen, accounting for navbar height */
  padding: 20px;
  border-radius: 10px;
  background-color: #2b2f33;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
}

/* Adjustments to other elements inside chat container */
.fullscreen-chat-container .chat-messages {
  max-height: calc(100vh - 150px); /* Increase height for messages in full-screen */
}

.fullscreen-chat-container .chat-container-icons {
  margin-bottom: 20px; /* Increase bottom margin in full-screen mode */
}

.fullscreen-chat-container .message-input {
  width: 90%; /* Wider input area in full-screen */
  max-width: 800px; /* Limit the width of the input box */
}

.chat-container {
  height: calc(100vh - 120px);
  padding: 20px;
  border:none;
  background-color: #2b2f33;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; 
  align-items: center;
  position: relative;
  margin-top: -5px;
  margin-bottom: 10px;
  color: #fff;
}

/* Updated text style */
.chat-container p {
  font-size: 16px;
  font-weight: bold;
  text-align: center;

}


.canvas-container {
  height: calc(100vh - 120px);
  padding: 20px;
  border-radius: 20px;
  background-color: #2b2f33;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-end; 
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  color: #fff;
}

/* Updated text style */
.canvas-container p {
  font-size: 16px;
  font-weight: bold;
  text-align: center;

}


/* Options list */
.chat-container-ul {
  font-size: 14px;
  color: white;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0px;
  text-align: left;
}

.chat-container-li {
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 10px;
  background-color: #282c34;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chat-container-li:hover {
  background-color: #3a3f47;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.chat-container-li.selected {
  background-color: #61dafb;
  color: #20232a;
}

.chat-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto; /* Enables vertical scrolling */
  background-color: transparent; /* Dark background to match space theme */
  border: none;
  border-radius: 8px;
  margin-bottom: 10px;
  color: #d1d1d1; /* Light text for better readability */
}

/* Custom Scrollbar for the space-dark theme */
.chat-messages::-webkit-scrollbar {
  width: 8px;
  background-color: #1c1e24; /* Match the background */
}

.chat-messages::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #4b4e5c, #252832); /* Gradient for space-like feel */
  border-radius: 4px;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #5d6070, #2e313a); /* Lighter gradient on hover */
}

.chat-messages::-webkit-scrollbar-track {
  background-color: #1c1e24; /* Same as background to blend in */
}


.chat-message {
  margin-bottom: 10px;
}

.chat-message.user {
  text-align: right;
  font-size: 18px; /* Limit font size to 8px for user messages */
}

.chat-message.assistant {
  text-align: left;
  font-size: 18px; /* Limit font size to 8px for assistant messages */
}

.chat-message strong {
  display: block;
  margin-bottom: 5px;
}

.assistant-response-container {
  position: relative; /* Ensure buttons are positioned relative to the response */
}

.assistant-response-container:hover .pdf-response-buttons {
  display: flex; /* Show buttons when hovering */
}

.assistant-response-container:hover .response-buttons {
  display: flex; /* Show buttons when hovering */
}


.response-buttons {
  display: none; /* Initially hide the buttons */
  position: absolute; /* Position them absolutely */
  bottom: -40px; /* Adjust this value to place buttons below the response */
  left: 0; /* Align to the left */
  background-color: transparent; /* Ensure background is transparent */
  border: none; /* Remove borders */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}

.pdf-response-buttons {
  display: none; /* Initially hide the buttons */
  position: absolute; /* Position them absolutely */
  bottom: -100px; /* Adjust this value to place buttons below the response */
  left: 0; /* Align to the left */
  background-color: transparent; /* Ensure background is transparent */
  border: none; /* Remove borders */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}


.chat-message:hover .response-buttons {
  display: flex; /* Show buttons when hovering over the message */
  gap: 2px; /* Add space between buttons */
}

.response-buttons button {
  background-color: transparent; /* Make background transparent */
  border: none; /* Remove borders */
  cursor: pointer; /* Change cursor to pointer on hover */
  color: white; /* Set a color for the icons */
  transition: color 0.3s; /* Add transition for smooth color change */
}

.pdf-response-buttons button {
  background-color: transparent; /* Make background transparent */
  border: none; /* Remove borders */
  cursor: pointer; /* Change cursor to pointer on hover */
  color: white; /* Set a color for the icons */
  transition: color 0.3s; /* Add transition for smooth color change */
  height: 30px; /* Set a specific height for the buttons */
  padding: 5px; /* Add slight padding */
}

.thinking-indicator {
  display: flex;
  align-items: center;
  margin-top: 5px; /* Adjust spacing as needed */
}

.thinking-dot {
  width: 8px; 
  height: 8px; 
  border-radius: 50%;
  background-color: white; 
  margin: 0 4px 10px 4px;
  animation: pulse 1s infinite; 
}

/* Animation for dots */
@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
}


input[type="text"] {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: calc(100% - 22px); /* Adjust for padding and border */
  margin-bottom: 10px;
}

button {
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}

input[type="file"] {
  margin-bottom: 10px;
}


/* Icon buttons */
.chat-container-icons {
  position: absolute;
  right: -20px; 
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chat-container-icons button {
  background: none;
  border: none;
  cursor: pointer;
  color: #8055f5;
  transition: color 0.3s ease, transform 0.2s ease;
  position: relative; 
  width:30px;
  padding: 0;
  margin-left: 0px;
}

.chat-container-icons button:hover {
  color: #007bff;
  transform: scale(1.1);
}

.prompt-container {
  display: flex;
  flex-direction: column; /* Ensures stacking behavior */
  align-items: flex-start; /* Aligns the elements to the start */
  padding: 4px 8px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 95%;
  margin: 10px auto;
  position: relative;
  bottom: 0;
  height: auto; /* Height adjusts automatically */
  min-height: 35px;
}

.image-preview {
  margin-bottom: 10px; /* Spacing between the image and elements below */
}

.input-row {
  width: 100%;
  display: flex;
  flex-direction: row; 
}

.chat-send-button {
  align-self: flex-end; /* Stick to the bottom */
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
}

.chat-upload-button {
  align-self: flex-end; /* Stick to the bottom */
  margin-right: 10px; /* Add some space between the upload button and textarea */
  cursor: pointer;
}

textarea {
  flex: 1;
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  border-radius: 8px;
  outline: none;
  resize: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
  min-height: 30px;
  max-height: 300px; /* Allows dynamic growth */
  overflow-y: auto;
}


.prompt-container textarea::placeholder {
  color: #aaa;
  opacity: 0.8;
}

.prompt-container textarea:focus {
  box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.3);
}

/* Optional: Adjust the height of the textarea as the message grows */
.prompt-container textarea {
  max-height: 300px; /* About 10 lines */
  overflow-y: auto;
}

/* Scrollbar style for textarea */
.prompt-container textarea::-webkit-scrollbar {
  width: 8px;
}

.prompt-container textarea::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

/* Fullscreen chat container */
.chat-fullscreen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999; /* Ensure it's on top of all other elements */
  background-color: black; /* Make sure it has a background to avoid transparent issues */
  display: flex; /* Use flexbox for proper layout */
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
}

/* Hide the navbar and sidebar in fullscreen mode */
.chat-fullscreen .main-content,
.chat-fullscreen .navbar,
.chat-fullscreen .sidebar {
  display: none;
}

.chat-fullscreen .content-inner {
  width: 100%;
  height: 100%;
  display: flex; /* Ensure content layout is maintained in full-screen mode */
  flex-direction: column; /* Align content vertically */
}


.chat-fullscreen .chat-content-inner {
  width: 100%;
  height: 100%;
  display: flex; /* Ensure content layout is maintained in full-screen mode */
  flex-direction: column; /* Align content vertically */
}

/* Ensure the chat messages area fills the full screen */
.chat-fullscreen .chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  color: white; /* Set text color to white to ensure it's visible on the black background */
}

/* Styling for chat messages in full screen */
.chat-fullscreen .chat-message {
  margin-bottom: 10px;
  font-size: 14px; /* Make sure the font size is legible */
}

.chat-fullscreen .chat-message.user {
  text-align: right;
  color: #00ff00; /* User messages in a visible color */
}

.chat-fullscreen .chat-message.assistant {
  text-align: left;
  color: #ff00ff; /* Assistant messages in a different visible color */
}

/* Styles for the audio play bar */
.audio-play-bar {
  background-color: #333; /* Dark background for the play bar */
  padding: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Soft shadow */
}

/* Audio control buttons */
.audio-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Full width */
  max-width: 400px;
  margin-bottom: 10px; /* Space between controls and progress bar */
}

.audio-controls button {
  background-color: #444;
  border: none;
  color: white;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.audio-controls button:hover {
  background-color: #555;
}

/* Progress bar container */
.audio-progress-container {
  width: 100%; /* Full width */
  max-width: 400px;
}

/* Audio progress bar (input range) */
.audio-progress-bar {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  background-color: #888;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.2s;
}

.audio-progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add shadow for thumb */
}

.audio-progress-bar::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}


/* PDF Viewer Modal */
.pdf-viewer-model {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  transition: width 0.3s ease-in-out; /* Smooth transition */
}

.modal-container {
  display: flex;
  border-radius: 8px;
  transition: width 0.3s ease-in-out; /* Smooth transition */
}

.pdf-text-button {
  background-color: #2b2f33;
  border-radius: 10px;
  font-size: 10px;
  margin: 0 3px;
  cursor: pointer;
  color: white;
  text-transform: none;
}

.chat-sidebar {
  margin-top: 10px;
}

.chat-window {
  flex: 1; 
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.2);
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.chat-header {
  display: flex;
  height: 30px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid darkblue;
  user-select: none;
}

.chat-content {
  flex: 1;
  overflow-y: auto; /* Enable scrolling */
  padding: 10px;
  background-color: #1c1e24; 
  border: none;
  margin-top: 10px;
  margin-bottom: 2px;
  border-radius: 8px;
  color: #d1d1d1;
  scrollbar-width: thin; /* Firefox specific */
  scrollbar-color: #888 #1c1e24; /* Firefox specific */
}

/* Custom styles for the scrollbar in Webkit-based browsers (Chrome, Safari) */
.chat-content::-webkit-scrollbar {
  width: 8px;
}

.chat-content::-webkit-scrollbar-track {
  background: #1c1e24;
}

.chat-content::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 4px;
}

.chat-content::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

.cancel-chat-button {
  font-size: 14px;
  padding: 5px;
  cursor: pointer;
  color: red;
}

.chat-input-container {
  bottom: 16px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 0 0 8px 8px;
}

.chat-input {
  flex: 1;
  padding: 4px;
  font-size: 16px; /* Slightly larger font size */
  border: none; /* Removed border */
  border-radius: 10px;
  background: #2b2f33; /* Dark background for modern look */
  color: white;
  bottom: 0;
  resize: vertical;
  overflow-y: auto;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3); /* Stylish shadow */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.chat-send-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 10px;
}

.leaving-overlay {
  top: 0px; /* Increase the value to move the text farther down */
  position: relative; /* Ensure the element is positioned relative to its parent */
  color: white;
  font-weight: bold; 
  font-size: 18px;
  text-transform: uppercase; 
}

.video-player-container {
  position: relative;
  margin-bottom: 20px;
}

.youtube-url-input {
  width: 45%;
  padding: 8px;
  margin-bottom: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.youtube-url-input {
  flex-grow: 1;
  min-width: 180px; /* Ensure minimum width */
}

.save-video-button {
  display: flex;
  width: 70px;
  height: 40px;
  align-items: center;
  background-color: green;
  color: white;
  border: none;
  padding: 4px 4px 4px 4px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  text-transform: none; 
  margin-top: -10px;
}
.save-video-button:hover {
  background-color: #45a049;
}

.video-container {
  width: 100%; /* Make the container span the full width */
  max-width: 100%; /* Ensure it doesn't exceed the parent's width */
  position: relative; /* Ensure proper positioning */
  padding-top: 56.25%; /* Maintain 16:9 aspect ratio */
}

.react-player {
  position: absolute; /* Absolutely position the player inside the container */
  top: 0;
  left: 0;
  width: 100%; /* Full width of the parent */
  height: 100%; /* Full height of the parent */
}

.transcript-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; 
  display: flex;
  flex-direction: column;
  background: #111;
  color: white;
  max-height: 100%; /* Control how tall the transcript can grow */
  overflow: hidden; 
  box-sizing: border-box;
  z-index: 10; /* Ensure it's on top if needed */
}

.transcript-container h3 {
  margin: 0;
  padding: 10px;
}

.video-transcript {
  margin-top: 10px; 
  flex: 1; /* Allows it to grow upward within the max-height limit */
  overflow-y: auto;
  border: 1px solid rgba(255,255,255,0.2);
  border-radius: 10px 10px 0 0;
  box-shadow: inset 0 0 15px rgba(255,255,255,0.2);
  background: black;
}



.video-file-list-container {
  margin-top: 5px;
}


.video-file-item:hover {
  background-color: #f0f0f0;
}

.video-url-input-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust space between input and button */
}


.sqrt-wrapper {
  all: initial; /* Reset all styles to initial values */
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 1em; /* Ensure consistent font size */
  line-height: normal; /* Reset line height */
  color: inherit; /* Inherit color from the surrounding text */
  margin-left: 5px;
}

.sqrt-symbol {
  font-size: 1.2em; /* Adjust size to match content */
  margin-right: 0; /* Remove extra space after the √ symbol */
  color: inherit; /* Ensure symbol color matches text */
}

.sqrt-content {
  all: unset; /* Reset any styles inherited from parent */
  position: relative;
  padding-left: 0.5em; /* Space between the √ symbol and content */
  display: inline-block; /* Ensure the content's dimensions are measurable */
  font-size: 1em; /* Match surrounding text size */
  color: inherit; /* Match surrounding text color */
}

.sqrt-overline {
  all: unset; /* Reset any styles inherited from parent */
  position: absolute;
  top: 0.1em; /* Slight adjustment to align with the top of the √ symbol */
  left: 8px; /* Start directly above the √ symbol */
  width: calc(100% - 0.4em); /* Extend to cover both the √ symbol and the content */
  height: 1px; /* Line thickness */
  border-top: 1.5px solid currentColor; /* Line style */
  pointer-events: none; /* Prevent interaction with the overline */
}


.lim-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-family: inherit; /* Matches surrounding text */
  font-size: inherit; /* Ensures consistency */
  line-height: 1; /* Avoid excessive spacing */
  position: relative;
}

.lim-symbol {
  font-size: 1em; /* Matches the main font size */
  font-weight: bold; /* Bold for emphasis */
  text-align: center;
}

.lim-sub {
  font-size: 0.8em; /* Subscript is slightly smaller */
  position: relative;
  bottom: -0.2em; /* Position the subscript closer */
  text-align: center;
  line-height: 1;
  font-weight: normal; /* Regular weight for subscript */
  white-space: nowrap; /* Prevent breaking of subscript variables */
}

/* Reset all inherited styles for the wrapper and its children */
.lim-wrapper,
.lim-wrapper * {
  all: unset; /* Unset all inherited styles */
  display: inline; /* Ensure inline rendering */
  font: inherit; /* Match surrounding text styles */
  color: inherit; /* Match surrounding text color */
  line-height: inherit;
  text-align: inherit;
  white-space: nowrap; /* Prevent line breaks in content */
}


/* Toolbar Container */
.toolbar-container {
  top: -30px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
}

/* Toolbar Buttons */
.toolbar-buttons {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  padding: 10px 10px;
  width: calc(100% - 60px);
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 0px;
  scrollbar-width: none; /* For Firefox */
}

.toolbar-buttons::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.toolbar-button {
  display: inline-block;
  padding: 8px 16px;
  margin: 0 8px;
  background-color: #24282c;
  color: white;
  border-radius: 12px;
  cursor: pointer;
  border: none;
  white-space: nowrap;
  text-transform: none;
}

.toolbar-button.selected {
  background-color: white; /* Highlight selected button */
  color: black; /* Make text stand out */
}

.toolbar-button:hover {
  transform: scale(1.05); /* Slight scaling effect on hover */
}

/* Scroll Buttons */
.scroll-button {
  position: absolute;
  z-index: 1;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 14px;
}

.left-scroll {
  left: 10px;
}

.right-scroll {
  right: 10px;
}

.resize-bar {
  width: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: col-resize;
  position: relative;
  z-index: 10;
  margin-right: 5px;
}

.resize-bar:hover {
  background-color: #aaa; /* Slight color change on hover for better visibility */
}

.chat-resize-bar {
  width: 5px;
  background-color: black;
  cursor: col-resize;
  position: relative;
  z-index: 10;
  margin-right: 5px;
}

.chat-resize-bar:hover {
  background-color: #aaa; /* Slight color change on hover for better visibility */
}



.zoom-buttons-container button {
  width: 30px; /* Set fixed width */
  height: 30px; /* Set fixed height */
}

.zoom-buttons-container button:hover {
  background-color: inherit;
  border: inherit;
  box-shadow: none;
  transform: none;
}

.tab-buttons {
  display: flex; /* Align buttons horizontally */
  justify-content: center; /* Center buttons */
  margin: 20px 0; /* Add some spacing around the buttons */
  gap: 10px; /* Add spacing between buttons */
}

.tab-buttons button {
  padding: 10px 20px; /* Adjust padding for a comfortable size */
  border: none; /* Remove default border */
  border-radius: 5px; /* Add rounded corners */
  background-color: #f0f0f0; /* Light background for unselected buttons */
  color: #333; /* Text color */
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
  font-size: 14px; /* Adjust font size */
  text-transform: none; 
}

.tab-buttons button:hover {
  background-color: #e0e0e0; /* Slightly darker background on hover */
  transform: scale(1.05); /* Slight scaling effect on hover */
}

.tab-buttons button.active {
  background-color: #004085;
  color: white; /* White text for contrast */
  font-weight: bold; /* Emphasize the active tab */
  transform: scale(1.05);
}

.summary-pdf-button {
  font-size: 14px;
  color: white;
  text-decoration: none;
  transition: all 0.2s ease;
  background: transparent;
}

.waveform-container {
  width: 100%; /* Full width */
  height: 100px; /* Adjust the height based on your needs */
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5; /* Optional background */
}

.waveform-container canvas {
  display: block;
}

.file-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Space between tags */
  margin-top: 8px; /* Add some spacing from other elements */
  font-size: 0.5em;
}

.pdf-page-input {
  width: 80px !important;
  text-align: center;
  border: none !important;
  background: transparent;
  color: white;
  padding: 0px;
  font-size: 14px;
  margin-left: 10px;
  margin-top: 10px; /* Adjust this value to move the input field down */
  user-select: none;
}

.audio-transcript {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative; 
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.2);
   --rpv-core__inner-page-background-color:  linear-gradient(145deg, rgba(0, 0, 255, 0.6), rgba(128, 0, 128, 0.6));
  --rpv-core__page-layer-box-shadow: 2px 2px 8px 0  linear-gradient(145deg, rgba(0, 0, 255, 0.6), rgba(128, 0, 128, 0.6));
  /*
  --rpv-core__annotation--link-hover-background-color: rgba(255, 255, 0, 0.2);
  --rpv-core__annotation-popup-wrapper-background-color: #faf089;
  --rpv-core__annotation-popup-wrapper-box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --rpv-core__annotation-popup-content-border-top-color: #1a202c;
  --rpv-core__arrow-border-color: rgba(0, 0, 0, 0.3);
  --rpv-core__asking-password-color: #000;
  --rpv-core__asking-password-wrapper-background-color: #fff;
  --rpv-core__asking-password-wrapper-border-color: rgba(0, 0, 0, 0.3);
  --rpv-core__button-background-color: rgba(0, 0, 0, 0.3);
  --rpv-core__button-color: #000;
  --rpv-core__doc-error-background-color: #fff;
  --rpv-core__doc-error-text-background-color: #c02424;
  --rpv-core__doc-error-text-color: #fff;
  --rpv-core__doc-loading-background-color: #fff;
  --rpv-core__full-screen-target-background-color: #fff;
  --rpv-core__inner-page-background-color: #fff;
  --rpv-core__menu-divider-border-bottom-color: rgba(0, 0, 0, 0.3);
  --rpv-core__menu-item-color: #000;
  --rpv-core__menu-item--hover-background-color: rgba(0, 0, 0, 0.1);
  --rpv-core__menu-item--disabled-color: rgba(0, 0, 0, 0.3);
  --rpv-core__minimal-button-color: #000;
  --rpv-core__minimal-button--hover-background-color: rgba(0, 0, 0, 0.1);
  --rpv-core__minimal-button--disabled-color: rgba(0, 0, 0, 0.3);
  --rpv-core__minimal-button--selected-background-color: rgba(0, 0, 0, 0.1);
  --rpv-core__modal-body-background-color: #fff;
  --rpv-core__modal-body-border-color: rgba(0, 0, 0, 0.3);
  --rpv-core__modal-overlay-background-color: rgba(0, 0, 0, 0.5);
  --rpv-core__page-layer-box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  --rpv-core__popover-body-background-color: #fff;
  --rpv-core__popover-body-border-color: rgba(0, 0, 0, 0.3);
  --rpv-core__popover-body-color: #000;
  --rpv-core__primary-button-background-color: #2566e8;
  --rpv-core__primary-button-color: #fff;
  --rpv-core__progress-bar-background-color: rgba(0, 0, 0, 0.1);
  --rpv-core__progress-bar-progress-background-color: #2566e8;
  --rpv-core__progress-bar-progress-color: #fff;
  --rpv-core__separator-border-bottom-color: rgba(0, 0, 0, 0.3);
  --rpv-core__spinner-border-color: rgba(0, 0, 0, 0.4);
  --rpv-core__spinner-border-transparent-color: transparent;
  --rpv-core__splitter-background-color: transparent;
  --rpv-core__splitter--hover-background-color: rgba(0, 0, 0, 0.2);
  --rpv-core__text-layer-text--selection-background-color: rgb(0, 0, 255, 1);
  --rpv-core__text-layer-text--selection-color: transparent;
  --rpv-core__textbox-background-color: #fff;
  --rpv-core__textbox-border-color: rgba(0, 0, 0, 0.2);
  --rpv-core__textbox-color: #000;
  --rpv-core__tooltip-body-background-color: #000;
  --rpv-core__tooltip-body-color: #fff;
  --rpv-drop__area-background-color: #fff;
  --rpv-drop__area-body-border-color: rgba(0, 0, 0, 0.3);
  --rpv-drop__area-body-color: #000;
  --rpv-full-screen__overlay-background-color: #fff;
  --rpv-print__progress-body-background-color: #fff;
  --rpv-print__progress-body-border-color: rgba(0, 0, 0, 0.3);
  --rpv-print__progress-body-color: #000;
  --rpv-zoom__popover-target-arrow-border-color: rgba(0, 0, 0, 0.6);
  --rpv-toolbar__label--color: #000;
  --rpv-search__highlight-background-color: rgba(255, 255, 0, 0.4);
  --rpv-search__highlight--current-background-color: rgba(0, 128, 0, 0.4); */
}


.transcript-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%; /* Make the container fill the viewport height */
  background: transparent; /* Optional: For contrast */
  color: white;
  overflow: hidden; /* Prevent overflow beyond screen height */
}

.transcript-container h3 {
  margin: 0; /* Remove default margins */
  padding: 10px; /* Some spacing around the heading if desired */
}

.video-transcript {
  margin-top: 10px; /* 10px gap below the heading */
  flex: 1; /* Let this element grow to fill the rest of the screen */
  overflow-y: auto; /* Scrollable if content exceeds available space */
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px 10px 0 0;
  box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.2);
  background: transparent;
  --rpv-core__inner-page-background-color:  linear-gradient(145deg, rgba(0, 0, 255, 0.6), rgba(128, 0, 128, 0.6));
  --rpv-core__page-layer-box-shadow: 2px 2px 8px 0  linear-gradient(145deg, rgba(0, 0, 255, 0.6), rgba(128, 0, 128, 0.6));
}


.animation-window {
  display: flex;
  flex-direction: column; /* So we can place the label at the top */
  align-items: flex-start; /* Left align contents */
  justify-content: flex-start; /* Start at the top */
  padding-left: 5%;
  background: linear-gradient(145deg, rgba(0, 0, 255, 0.2), rgba(128, 0, 128, 0.2));
  position: relative;
}

.window-label {
  margin-top: 20px;
  margin-left: 0;
  font-size: 1.5rem;
  color: orange; /* Orange color for the label text */
  font-weight: bold;
  font-family: 'Arial', sans-serif;
}

.animation-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: 'Arial', sans-serif;
  animation: fadeIn 1s ease-in-out;
  margin-top: 100px; /* Slight spacing after the label */
}

.animation-text h1,
.animation-text p {
  color: orange; /* Orange color for all animation window texts */
}

.animation-text h1 {
  font-size: 3rem;
  margin: 0;
  font-weight: bold;
}

.animation-text p {
  font-size: 1.5rem;
  margin: 10px 0 0 0;
  font-weight: normal;
}

.auth-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color:black; 
  background-image: url('https://www.transparenttextures.com/patterns/stardust.png');
  color: #FFFFFF; 
}

.get-started {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
  text-align: center;
}

.auth-buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 1.5rem;
}

.auth-buttons button {
  width: 40%;
  padding: 10px 15px;
  font-size: 1rem;
  color: #FFFFFF;
  background-color: #4CAF50; /* Green button color */
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-buttons button:hover {
  background-color: #45a049; /* Slightly darker green */
}

.try-it-first {
  font-size: 1rem;
  margin-bottom: 2rem;
  text-align: center;
}


.footer {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers items horizontally */
  justify-content: center; /* Centers items vertically */
  margin-top: 200px; /* Existing spacing as needed */
}

/* Change the footer text to gray inside auth-box */
.auth-box .footer, 
.auth-box .footer * {
  color: gray;
}

.footer-logo {
  width: 60px;
  margin-bottom: 10px;
}

.footer-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.footer-links a {
  font-size: 0.9rem;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

/* Keyframes for smooth fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.typing-circle {
  display: inline-block;
  width: 20px;    /* Adjust size as needed */
  height: 20px;   /* Adjust size as needed */
  border-radius: 50%;
  background-color: orange;
  margin-left: 5px; /* Spacing between text and the circle */
}

body.cropping-mode {
  user-select: none;
  cursor: crosshair !important;
}

.rpv-search__highlight {
  pointer-events: none; /* Allows events to pass through to underlying elements */
}

.qna-content {
  width: 100%;
  flex: 1; /* Allows main content to grow and fill available space */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.additional-content {
  min-height: 60px; /* Reserve space to prevent layout shifts */
  width: 100%;
  text-align: center;
}

.welcome {
  display: flex; /* Use flexbox layout */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-align: center; /* Center text */
  position: absolute; /* Position the container absolutely within its parent */
  top: 33%; /* Move it to about 1/3 from the top */
  width: 100%; /* Ensure it takes full width */
  flex-direction: column; /* Stack items vertically */
}

.welcome h2 {
  color: white; 
}

.welcomeImage {
  width: 5%;
  max-width: 420px;
}

.crop-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: crosshair; /* Indicate cropping mode */
  background: rgba(255, 255, 255, 0); /* Transparent background */
  z-index: 99999; /* Ensure it sits above the PDF viewer */
}

/* Add this to your CSS file */
.cropping-mode * {
  cursor: crosshair !important;
  user-select: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
}

.navigation-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Adjust the gap between buttons as needed */
  margin-top: 20px;
}

.nav-qna-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-qna-button:hover {
  opacity: 0.8;
}

.model-option {
  align-self: flex-end;
  position: relative;
  display: inline-block; /* Ensure the container only takes up as much space as needed */
  margin-left: 10px; /* Adjust margin to align it properly within the container */
  margin-top: 10px; /* Reduce the gap between the model-option and the container above */
}

.model-option select {
  appearance: none; /* Remove default arrow */
  background-color: #333;
  color: white;
  border: none;
  padding: 5px 25px 5px 10px; /* Add padding to the right for the icon */
  border-radius: 5px;
  cursor: pointer;
  width: 100px; /* Fixed width for the select box */
  font-size: 14px; /* Adjust font size if needed */
}

.model-option select:focus {
  outline: none; /* Remove outline on focus */
}

/* Custom upward arrow */
.model-option::after {
  content: '▼';
  position: absolute;
  top: 50%;
  right: 8px; /* Move the icon to the right side */
  transform: translateY(-50%);
  pointer-events: none; /* Ensure clicks pass through to the select element */
  color: white;
  font-size: 12px; /* Adjust size of the arrow */
}

.model-option-chat {
  align-self: flex-end;
  position: relative;
  display: inline-block; /* Ensure the container only takes up as much space as needed */
  margin-left: 10px; /* Adjust margin to align it properly within the container */
  margin-top:  10px; /* Reduce the gap between the model-option and the container above */
  bottom: 0px;
}

.model-option-chat select {
  appearance: none; /* Remove default arrow */
  background-color: #333;
  color: white;
  border: none;
  padding: 5px 25px 5px 10px; /* Add padding to the right for the icon */
  border-radius: 5px;
  cursor: pointer;
  width: 100px; /* Fixed width for the select box */
  font-size: 14px; /* Adjust font size if needed */
}

.model-option-chat select:focus {
  outline: none; /* Remove outline on focus */
}

/* Custom upward arrow */
.model-option-chat::after {
  content: '▲';
  position: absolute;
  top: 50%;
  right: 8px; /* Move the icon to the right side */
  transform: translateY(-50%);
  pointer-events: none; /* Ensure clicks pass through to the select element */
  color: white;
  font-size: 12px; /* Adjust size of the arrow */
}
